// Because images are renamed on build. With this we get the name of the final file
const pictureQuestion1 = require('./img/01-quest.png');
const pictureQuestion2 = require('./img/02-quest.png');
const pictureQuestion3 = require('./img/03-quest.png');
const pictureQuestion4 = require('./img/04-quest.png');
const pictureQuestion5 = require('./img/05-quest.png');
const pictureQuestion6 = require('./img/06-quest.png');

const GameState = {
	playing: 0,
	done: 1,
	results: 2,
};

document.setDefaultFontSize = function() {
	const container = document.getElementById('game-container');
	const containerWidth = container.clientWidth;
	let fontSize = Math.round(20 * containerWidth / 1055);
	document.documentElement.style.setProperty('--default-font-size', `${fontSize}px`);
}

document.setGameContainerSize = function() {
	const width = window.innerWidth;
	const height = window.innerHeight;
	const container = document.getElementById('game-ratio-container');

	if (height < (width * 2.8 / 4)) {
		container.style.width = `${Math.floor(height * 4 / 2.8)}px`;
	} else {
		container.style.width = `${width}px`;
	}
}

document.computeSizes = function() {
	document.setGameContainerSize();
	document.setDefaultFontSize();
}

document.Game = {
	// state: GameState.playing,
	state: GameState.done,
	currentQuestionIndex: 0,
	questions: [
		{
			picture: pictureQuestion1,
			answer: false,
			explanation: `<p>Il faut éviter les excès de pression afin de limiter la progression de la maladie<sup>1</sup>.</p><p>Le port répété de charges lourdes, l'agenouillement ou l'accroupissement fréquents sont des facteurs de risque de l'arthrose du genou<sup>2</sup>.</p>`,
			references: `<p>1. Inserm (2012). Arthrose. [en ligne] https://www.inserm.fr/dossier/arthrose/ - consulté le 08.06.2022<br/>2. Gignac, M. A., Irvin, E., Cullen, K., Van Eerd, D., Beaton, D. E., Mahood, Q., Backman, C. L. (2020). Men and women's occupational activities and the risk of developing osteoarthritis of the knee, hip, or hands: a systematic review and recommendations for future research. Arthritis care & research, 72(3), 378-396.</p>`,
		},
		{
			picture: pictureQuestion2,
			answer: true,
			explanation: `<p>La natation réduit les douleurs et la raideur lorsqu'elle est pratiqué de façon modérée et régulière. Parallèlement, il améliore les performances physiques et la capacité fonctionnelle des patients atteints d'arthrose<sup>3</sup>. C'est une activité physique recommandée en cas d'arthrose du genou<sup>4</sup>.</p>`,
			references: `<p>3. Ziltener, J., Leal, S., Borloz, S. (2012), Activités physiques – sport et arthrose, Rev Med Suisse, -2, no. 332, 564-570.<br/>4. Alkatan, M., Baker, J. R., Machin, D. R., Park, W., Akkari, A. S., Pasha, E. P., & Tanaka, H. (2016). Improved function and reduced pain after swimming and cycling training in patients with osteoarthritis. The Journal of rheumatology, 43(3), 666-672.</p>`,
		},
		{
			picture: pictureQuestion3,
			answer: true,
			explanation: `<p>Le cyclisme réduit les douleurs et la raideur lorsqu'il est pratiqué de façon modérée et régulière. Parallèlement, il améliore les performances physiques et la capacité fonctionnelle des patients atteints d'arthrose<sup>3</sup>. C'est une activité physique recommandée en cas d'arthrose du genou<sup>4</sup>.</p>`,
			references: `<p>3. Ziltener, J., Leal, S., Borloz, S. (2012), Activités physiques – sport et arthrose, Rev Med Suisse, -2, no. 332, 564-570.<br/>4. Alkatan, M., Baker, J. R., Machin, D. R., Park, W., Akkari, A. S., Pasha, E. P., & Tanaka, H. (2016). Improved function and reduced pain after swimming and cycling training in patients with osteoarthritis. The Journal of rheumatology, 43(3), 666-672.</p>`,
		},
		{
			picture: pictureQuestion4,
			answer: false,
			explanation: `<p>Jouer au football peut être préjudiciable à la santé du genou. Il est préférable de limiter sa pratique intense<sup>3,5</sup>.</p>`,
			references: `<p>3. Ziltener, J., Leal, S., Borloz, S. (2012), Activités physiques – sport et arthrose, Rev Med Suisse, -2, no. 332, 564-570.<br/>5. Lo, G. H., McAlindon, T. E., Kriska, A. M., Price, L. L., Rockette-Wagner, B. J., Mandl, L. A., Eaton, C. B., Hochberg, M. C., Jackson, R. D., Kwoh, C. K., Nevitt, M. C., & Driban, J. B. (2020). Football Increases Future Risk of Symptomatic Radiographic Knee Osteoarthritis. Medicine and science in sports and exercise, 52(4), 795–800.</p>`,
		},
		{
			picture: pictureQuestion5,
			answer: true,
			explanation: `<p>L’arthrose du genou ne compromet pas le fait de faire ses courses ! L'utilisation de chariots est recommandée afin de faciliter les déplacements du patient. Il peut ainsi s’y appuyer et limiter le port de poids lourds fortement déconseillé en cas d'arthrose du genou<sup>6</sup>.</p>`,
			references: `<p>6. Lagorce, T., Buxeraud, J., & Guillot, X. (2016). Les traitements de l’arthrose. Actualités Pharmaceutiques, 55(555), 23-29.</p>`
		},
		{
			picture: pictureQuestion6,
			answer: false,
			explanation: `<p>Il existe une prévalence accrue de l’arthrose du genou chez les athlètes d'haltérophilie<sup>7</sup>. Cependant, le renforcement musculaire reste recommandé en utilisant les poids faibles et en adoptant les bonnes postures<sup>3</sup>.</p>`,
			references: `<p>3. Ziltener, J., Leal, S., Borloz, S. (2012), Activités physiques – sport et arthrose, Rev Med Suisse, -2, no. 332, 564-570.<br/>7. Driban, J. B., Hootman, J. M., Sitler, M. R., Harris, K. P., & Cattano, N. M. (2017). Is participation in certain sports associated with knee osteoarthritis? A systematic review. Journal of athletic training, 52(6), 497-506.</p>`,
		},
	],
	render() {
		// Hide all game state
		const gameStates = document.getElementsByClassName('game-state');
		for (let i = 0; i < gameStates.length; i++) {
			if (!gameStates[i].classList.contains('hidden')) {
				gameStates[i].classList.add('hidden');
			}
		}
		// Call method to prepare everything then display the current state
		switch (this.state) {
			case GameState.playing:
				this._renderCurrentQuestion();
				document.getElementById('state-playing').classList.remove('hidden')
				break;
			case GameState.done:
				document.getElementById('state-done').classList.remove('hidden')
				break;
			case GameState.results:
				document.getElementById('state-results').classList.remove('hidden')
				break;
		}
	},
	_renderCurrentQuestion() {
		// Hide answer before rendering a new question
		const gameAnswerContainer = document.getElementById('game-answers');
		if (!gameAnswerContainer.classList.contains('hidden')) {
			gameAnswerContainer.classList.add('hidden');
		}
		// Deactivate previous selection before rendering a new question
		const gameActionButtons = document.getElementById('game-actions').getElementsByTagName('button');
		for (let i = 0; i < gameActionButtons.length; i++) {
			const button = gameActionButtons[i];
			if (button.classList.contains('selected')) {
				button.classList.remove('selected');
			}
		}

		document.getElementById('game-content')
			.getElementsByTagName('img')[0]
			.src = this.questions[this.currentQuestionIndex].picture;
	},
	answer(selectedAnswer) {
		const currentQuestion = this.questions[this.currentQuestionIndex];
		// Set the right style on button
		const gameActionButtons = document.getElementById('game-actions').getElementsByTagName('button');
		for (let i = 0; i < gameActionButtons.length; i++) {
			const button = gameActionButtons[i];
			if (button.classList.contains('selected')) {
				button.classList.remove('selected');
			}
			if ((selectedAnswer && i === 0) || (!selectedAnswer && i === 1)) {
				button.classList.add('selected');
			}
		}
		// Fill in the answer
		const gameAnswerContainer = document.getElementById('game-answers');
		const answerContainer = document.getElementById('answer');
		const answerDetailsContainer = document.getElementById('answer-details');
		const answerReferencesContainer = document.getElementById('answer-references');

		answerContainer.innerHTML = selectedAnswer === currentQuestion.answer ? 'Exact !' : 'Faux !';
		answerDetailsContainer.innerHTML = currentQuestion.explanation;
		answerReferencesContainer.innerHTML = `Références :<br/>${currentQuestion.references}`;

		if (gameAnswerContainer.classList.contains('hidden')) {
			gameAnswerContainer.classList.remove('hidden');
		}
	},
	restart() {
		this.currentQuestionIndex = 0;
		this.state = GameState.playing;
		this.render();
	},
	next() {
		this.currentQuestionIndex += 1;
		if (this.currentQuestionIndex === this.questions.length) {
			this.state = GameState.done;
		}
		this.render();
	},
	seeResults() {
		// Push action you can do
		const actionAllowedContainer = document.getElementById('results-actions-allowed')
			.getElementsByClassName('results-actions-list')[0];
		actionAllowedContainer.innerHTML = this.questions.filter(q => q.answer === true)
			.map(q => `<img src="${q.picture}" />`)
			.join('');

		// Push action you can't do
		const notActionAllowedContainer = document.getElementById('results-actions-not-allowed')
			.getElementsByClassName('results-actions-list')[0];
		notActionAllowedContainer.innerHTML = this.questions.filter(q => q.answer === false)
			.map(q => `<img src="${q.picture}" />`)
			.join('');

		this.state = GameState.results;
		this.render();
	}
}

document.computeSizes();
document.Game.restart();
addEventListener('resize', document.computeSizes);

